import React, { useState, useEffect } from 'react';
import './RepetitionGroup.css'; // Import CSS
import copy from '../../assets/copy_btn.svg';
import share from '../../assets/share_btn.svg';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import {config} from '../../config'


const History = ({ onDocumentSelect }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        logEvent(analytics, 'component_view_essay', {
            component_name: 'History'
        });
    
        const userProfile = JSON.parse(localStorage.getItem('userProfile'));

        if (userProfile && userProfile.id) {
            fetchHistory(userProfile.id);
        } else {
            setLoading(false);
            setError('Please log in to save and view your history.'); // Updated message for not logged in
        }
    }, []); // Runs once on component mount
    
    

    const fetchHistory = async (id) => {
        try {
            const response = await fetch(`${config.baseURL}/get_history.php?email=${encodeURIComponent(id)}`);
            const data = await response.json();

            if (data.success) {
                if (data.history && data.history.length > 0) {
                    setItems(data.history);
                } else {
                    setError('History not available.'); // Updated message for no history found
                }
            } else {
                setError(data.message || 'Failed to fetch history.');
                setItems([]);
            }
        } catch (error) {
            console.error('Error fetching history:', error);
            setError('An error occurred while fetching history.');
        } finally {
            setLoading(false);
        }
    };
    

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert('Text copied to clipboard!');
        logEvent(analytics, 'text_copied_essay', {
            text_length: text.length
        });
    
    };

    const handleShare = async (text) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: "Generated Essay",
                    text: text,
                });
                logEvent(analytics, 'document_shared_essay', {
                    text_length: text.length
                });
            }
        } catch (error) {
            console.error('Error while sharing:', error);
            alert('Sharing failed. Please try again.');
        }
    };

    const handleDocumentClick = (item) => {
        onDocumentSelect(item.ai_result);
        logEvent(analytics, 'History_clicked_essay', {
            document_id: item.id,
            document_preview: item.ai_result.substring(0, 50) // Preview of the text
        });
    };

    // Loading, error, and empty state handling
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className='history_null'>{error}</div>;
    }

    return (
        <div>
        {items.slice().reverse().map((item, index) => {
            const wordLimit = item.ai_result.split(' ').length > 5 ? 3 : 2; // Show 3 words if more than 5, otherwise 2
            const firstWords = item.ai_result.split(' ').slice(0, wordLimit).join(' '); // Get the first words
            return (
                <div className="repeating-group" key={index} onClick={() => handleDocumentClick(item)}>
                    <span>{firstWords}</span> {/* Show only the specified number of words */}
                    <div className="icon-container">
                        <span style={{ marginRight: '10px' }}>
                            <img 
                                src={copy} 
                                alt="Copy" 
                                onClick={(e) => { e.stopPropagation(); handleCopy(item.ai_result); }} 
                            />
                        </span>
                        <span>
                            <img 
                                src={share} 
                                alt="Share" 
                                onClick={(e) => { e.stopPropagation(); handleShare(item.ai_result); }} 
                            />
                        </span>
                    </div>
                </div>
            );
        })}
    </div>
    );
};

export default History;

import React, { useState, useEffect } from 'react';
import './Buy.css';
import { IoAlertOutline } from "react-icons/io5";
import { toast } from 'react-toastify';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config'
import GoogleButton from './GoogleAuth';
import { useRef } from 'react';

const Buy = ({ onBack }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPayPalPopup, setShowPayPalPopup] = useState(false);
    const [modalmessage, setModalmessage] = useState('');
    const [showmodal, setShowmodal] = useState(false);    
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userProfile')));
    const premiumButtonRef = useRef(null);

    useEffect(() => {
        const fetchPaypalPlans = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${config.baseURL}/get_paypal_plans.php`);
                if (!response.ok) throw new Error('Failed to fetch PayPal plans');
                const data = await response.json();
                const transformedPlans = data.plans_details.map(plan => {
                    const firstBillingCycle = plan.billing_cycles[0];
                    return {
                        id: plan.id,
                        name: plan.name,
                        currency: firstBillingCycle?.pricing_scheme?.fixed_price?.currency_code || 'USD',
                        amount: (parseFloat(plan?.billing_cycles?.[1]?.pricing_scheme?.fixed_price?.value) || 0) * 100,
                        interval: firstBillingCycle?.frequency?.interval_unit === 'WEEK' ? '1' : 
                                  (firstBillingCycle?.frequency?.interval_unit === 'MONTH' ? '2' : '2'),
                        intervalCount: firstBillingCycle?.frequency?.interval_count || 1,
                    };
                });
                setPlans(transformedPlans);
                logEvent(analytics, 'fetch_paypal_plans_essay', {
                    plans_count: data.plans_details.length
                });
                checkSubscription();
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPaypalPlans();
    }, []);

    useEffect(() => {
        logEvent(analytics, 'component_view_essay', {
            component_name: 'Buy'
        });
    }, []);

    const checkSubscription = async () => {
        const userEmail = JSON.parse(localStorage.getItem('userProfile'))?.email;
        if (!userEmail) return;

        try {
            const response = await fetch(`${config.baseURL}/check_subscription.php?user_email=${encodeURIComponent(userEmail)}`);
            const rawResponse = await response.text();
            const data = JSON.parse(rawResponse);

            if (!data.error) {
                localStorage.setItem('subscriptionStatus', JSON.stringify(data.subscription_status));
                localStorage.setItem('planId', data.plan_id);
                checkActivePlan();
            }
        } catch (error) {
            // Handle error appropriately
        }
    };

    const handleLoginSuccess = (userProfile) => {
        if (userProfile) {
          ;
            setShowModal(false);           
            localStorage.setItem('showComponent', 'true');
            window.location.reload();

            toast('Login successful.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover:false ,
                draggable: true,
                progress: undefined,
                theme: "light",
                
                });
                logEvent(analytics, 'login_click_essay_buyPage', {
                    button_name: 'login_button',
                });
                checkSubscription(userProfile.email);
                
                
        } else {
           
            
        }
        
    };

    const handleLoginFailure = (error) => {
      
        if (error.error) {
            alert(`Login failed: ${error.error}`);
        } else {
            
        }
    };

    const checkActivePlan = () => {
        const storedPlanId = localStorage.getItem('planId');
        const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));

        if (storedPlanId && subscriptionStatus === 'ACTIVE') {
            setPlans((prevPlans) =>
                prevPlans.map((plan) => {
                    const isActive = plan.id === storedPlanId;
                    return isActive
                        ? { ...plan, active: true }
                        : { ...plan, active: false };
                })
            );
        }
    };

    const handleSelect = (planId) => {
        setSelectedPlan(planId);
        localStorage.setItem('selectedPlan', planId);
        logEvent(analytics, 'plan_selected_essay', {
            plan_id: planId
        });
    };

    const handleSubscribe = () => {
        const isLoggedIn = Boolean(localStorage.getItem('userProfile'));

        if (!isLoggedIn) {
            setModalMessage('You need to log in to proceed.');
            setShowModal(true);
            return;
        }

        if (!selectedPlan) {
            setModalmessage('Please select a plan.');
            setShowmodal(true);
            return;
        }

        logEvent(analytics, '3 Days subscribe_attempt_essay', {
            plan_id: selectedPlan,
            user_logged_in: isLoggedIn
        });

        setShowPayPalPopup(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const closemodal = () => {
        setShowmodal(false);
    };

    const closePayPalPopup = () => {
        setShowPayPalPopup(false);
        document.body.style.overflow = '';
        logEvent(analytics, 'paypal_popup_closed', {
            user_action: 'cancel'
        });
    };

    useEffect(() => {
        if (showPayPalPopup) {
            const loadPaypalSdk = () => {
                const script = document.createElement('script');
                script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
                script.onload = () => {
                    renderPayPalButton();
                };
                document.body.appendChild(script);
            };

            const renderPayPalButton = () => {
                const paypalContainer = document.getElementById('paypal-button-container');
                if (paypalContainer) {
                    paypalContainer.innerHTML = '';

                    window.paypal.Buttons({
                        createSubscription: async (data, actions) => {
                            return await actions.subscription.create({
                                'plan_id': selectedPlan,
                            });
                        },
                        onApprove: async (data) => {
                            const userEmail = JSON.parse(localStorage.getItem('userProfile')).email;

                            await fetch(`${config.baseURL}/save_subscription.php`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    subscriptionId: data.subscriptionID,
                                    orderId: data.orderID,
                                    planId: selectedPlan,
                                    subscriberEmail: userEmail,
                                    status: 'ACTIVE',
                                }),
                            });

                            toast('You have successfully subscribed to the plan! Enjoy your benefits!', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            checkSubscription();
                            closePayPalPopup();
                        },
                        onError: (err) => {
                            console.error('PayPal Button Error:', err);
                            closePayPalPopup();
                        }
                    }).render(paypalContainer);
                }
            };

            loadPaypalSdk();
        }
    }, [showPayPalPopup]);

    return (
        <div className="plans-container">
            <div className="left-section">
                <div className='title_buy_premium'>
                    <button className="back-button-buy" ref={premiumButtonRef} onClick={onBack}>
                        <img className="back-button-buy" src={config.Backbutton} alt="Back" />
                    </button>
                          { config.title} <span><img src={config.proicon} alt="" className='proicon-buypage' /></span>
                </div>
                <div className="features">
                    <p className='titlethree'>Get Access To</p>
                    <ul>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' /> {config.getacceseline1}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline2}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline3}</li>
                        <li className='pointbuy'><img src={config.righticon} alt="" className='righticon' />{config.getacceseline4}</li>
                    </ul>
                </div>
            </div>
            {error && <p className="error-location">{error}</p>}
            <div className="plans-wrapper">
                {loading ? (
                    <div className="loading-spinner"></div>
                ) : plans.length > 0 ? (
                    plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`plan-box-${plan.interval} ${selectedPlan === plan.id || plan.active ? 'selected' : ''} ${plan.active ? 'Current Plan' : ''}`}
                            onClick={() => handleSelect(plan.id)}
                        >
                            <div className={`tag_plan-${plan.interval}-hover`}>
                                {plan.active ? 'Current Plan' : plan.name}
                            </div>
                            <div className='plan_content'>
                                <span className={`tag_plan-${plan.interval}`}>
                                    {plan.active ? 'Current Plan' : plan.name}
                                </span>
                                <p className='plans_text'><span className='discountedprice'>${(((plan.amount + 300) / 100) * (plan.amount === 2999 ? 1.999999 : 1)).toFixed(2)}</span> ${(plan.amount / 100)}  </p>
                                <p className='plans_text'>
                                    <b>{`${plan.currency} ${(plan.amount / 100).toFixed(2)}`}/
                                        {plan.name}</b>
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className='no-plans'>No plans available</p>
                )}
            </div>
            <div className="price-info">
                
                <button className="buy-button" onClick={handleSubscribe}>
                   
                    3 Days Free Trial
                    <img className="buy-button-img" src={config.Buybutton} alt="" /> 
                    
                </button>
                {selectedPlan && plans.length > 0 && (
                    (() => {
                        const selected = plans.find(plan => plan.id === selectedPlan);
                        if (selected && selected.name) {
                            return (
                                <p>
                                    3 Days Free Trial then ${` ${(selected.amount / 100).toFixed(2)}`} .
                                </p>
                            );
                        }
                        return null;
                    })()
                )}
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-icon">
                            <IoAlertOutline className="modal-img" />
                        </div>
                        <p className="modal-msg">{modalMessage}</p>
                        <GoogleButton id="modal-button" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                        <button id="modal-button" onClick={closeModal}> Close </button>
                    </div>
                </div>
            )}
            {showmodal && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-icon">
                            <IoAlertOutline className="modal-img" />
                        </div>
                        <p className="modal-msg">{modalmessage}</p>
                        <button id="modal-button" onClick={closemodal}> Close </button>
                    </div>
                </div>
            )}

            {showPayPalPopup && (
                <div className="paypal-popup">
                    <div className="popup-content">
                        <h2>Complete Your Purchase</h2>
                        <div id="paypal-button-container"></div>
                        <button onClick={closePayPalPopup} className="close-button">Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Buy;

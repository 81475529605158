import React, { useState, useEffect } from 'react';
import copyicon from '../../assets/copy_btn.svg';
import shareicon from '../../assets/share_btn.svg';
import './HistoryPreview.css';
import {config} from '../../config';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

const HistoryPreview = ({ result, onBack, GeneratedResult }) => {
  const [displayedText, setDisplayedText] = useState('');
  React.useEffect(() => {
       
    document.documentElement.style.setProperty('--main-color', config.color);
}, [config.color]);

  useEffect(() => {
    if (result) {
      // Directly set the displayed text
      setDisplayedText(result);
    }
  }, [result]);

  const handleCopy = () => {
    navigator.clipboard.writeText(displayedText);
    alert('Essay copied to clipboard!');
    logEvent(analytics, 'History_copy_essay', {
       // Preview of the text
  });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Generated Essay",
          text: displayedText,
          
        });
        logEvent(analytics, 'History_Share_essay', {
           // Preview of the text
      });
      }
    } catch (error) {
      console.error('Error while sharing:', error);
      alert('Sharing failed. Please try again.');
    }
  };

  return (
    <div className="generate-page-container">
      <div className="generate-topdiv">
        <div className='backbtntext'>
          <button className="back-button" onClick={onBack}>
            <img src={config.Backbutton} alt="Back" />
          </button>
          <span>Your Essay</span> {/* Changed title to "Your Document" */}
        </div>
        <div className="button-container">
          <img src={copyicon} alt="Copy" onClick={handleCopy} />
          <img src={shareicon} alt="Share" onClick={handleShare} />
        </div>
      </div>
      <pre
        id="generate-document-content"
        style={{
          whiteSpace: 'pre-wrap',
          fontFamily: 'Noto Sans',
          padding: '20px',
          borderRadius: '5px'
        }}
      >
        {displayedText}
      </pre>
    </div>
  );
};

export default HistoryPreview;

export const config = {
  title: 'AI Essay Writer',
  Writetab: "Write Essay",

  Topic : 'Topic',
  Topicplaceholder:'Create Essay here...',
  Keypoints : 'Select Type',
  Keypointsplaceholder:'',
  SupportiveInput: 'Select Tone',

 
 color:'#432F9F',
  getacceseline1: ' Unlimited Essay Writing',
  getacceseline2: ' Powerful AI Tool to Write Creative Essays',
  getacceseline3: ' 99.99% Plagiarism Free Essays',
  getacceseline4: ' Ads-Free Experience',




  //color used across web 
  primaryColor: '#432F9F',
  tabsColor: '#432F9F',


  //images used accross web
  logo: require('./assets/vector.svg').default,
  buyP: require('./assets/Group 1437252828.svg').default,
  home: require('./assets/filled.svg').default,
  homelp: require('./assets/filled leftp.svg').default,
  privacy: require('./assets/description.svg').default,
  privacylp: require('./assets/description leftp.svg').default,
  generate: require('./assets/button.svg').default,
  hamburger: require('./assets/USSSA - Icons_Hamburger Menu.svg').default,
  crown: require('./assets/crown 1.svg').default,
  frame: require('./assets/vector.svg').default,
  proicon: require('./assets/pro.svg').default,
  upgradetopro: require('./assets/upgradetopro.png')  ,
  righticon: require('./assets/right.svg').default,
  Backbutton: require('./assets/arrow-left.svg').default,  
  Buybutton: require('./assets/arrow long right.svg').default,
  book: require('./assets/book.svg').default, 




  //api endpoitns accross web
 // apiEndpoint: 'http://localhost/projectA/api',

 baseURL:"https://aiessaywritings.com//Backend",

 //baseURL:"http://localhost/",


  "FAQtitle": "How to Use Ai Essay Writer?",
  "points": [
    {
      "step": "",
      "details": [
        "For best results make sure you provide detailed instructions and utilize all selectors correctly.Select: paper type, subject area, number of pages, topic (should not be too long!), instructions (copy paste or add a file)."
      ]
    },
    {
      "step": "",
      "details": [
        "Then click on “Write My Essay” button and wait for results. On average it takes 2-4 seconds for our free essay writer to generate your essay."
      ]
    },
    {
      "step": "",
      "details": [
        "Your essay will be automatically converted into MS Word file in APA 7th edition format and downloaded for you. In some cases you may be prompted to save your file."
      ]
    },
    {
      "step": "",
      "details": [
        "For best results you may want to give it a few tries."
      ]
    }
    
  ],



  //privacy page 
  "privacyTitle": "Privacy Policy for AI Essay Writer",
  "privacyEffectiveDate": "13-7-2023",
  "privacyPoints": [
    {
      "heading": "1. Information We Collect",
      "subsections": [
        {
          "heading": "1.1 Personal Information",
          "content": [
            "We may collect personal information that you provide directly to us when using the App. This includes:",
            "- Your name, email address, and other contact information when you sign up or contact us for support.",
            "- Story content and other user-generated content you provide within the App.",
            "- Communication and interaction details when you communicate with other users or us through the App."
          ]
        },
        {
          "heading": "1.2 Usage Information",
          "content": [
            "When you use the App, we may automatically collect certain information about your device and usage patterns, including:",
            "- Device information (such as model, operating system version, unique device identifiers).",
            "- Log information (such as IP address, access dates and times, and referring/exit pages).",
            "- App usage data, including the features you use, the pages you visit, and the actions you take."
          ]
        }
      ]
    },
    {
      "heading": "2. Use of Information",
      "subsections": [
        {
          "heading": "2.1 Provide and Improve the App",
          "content": [
            "We use the collected information to provide, maintain, and improve the functionality and performance of the App. This includes:",
            "- Personalizing your experience and providing you with tailored content.",
            "- Enhancing the App's security and detecting, preventing, and responding to potential fraud or security issues."
          ]
        }
      ]
    },
    {
      "heading": "3. Sharing Your Information",
      "content": [
        "- We do not share your personal information with third parties, except as required by law or with your explicit consent."
      ]
    }
  ]
};
